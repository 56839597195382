.login-page {
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-box {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none !important;
    border-color: var(--bulma-border-color);
  }

  .login-logo {
    width: 400px;
    border-radius: 100%;
    overflow: hidden;
    //border: 1px solid $secondary;
    margin: -25dvh auto 0;
    //box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)
  }
}

.visibility-toggle-box {
  overflow: hidden;
  transition: max-height var(--bulma-speed) var(--bulma-easing);
}