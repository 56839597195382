@use "src/scss/base" as base;

$dimensions: 16 24 32 48 64 96 128 !default;

.image {
  align-items: center;
  justify-content: center;
  display: block;
  position: relative;

  img {
    height: 100%;
    display: block;
    width: 100%;
    object-fit: contain;
  }

  &.is-fullwidth {
    width: 100%;
  }

  &.is-padded {
    border-radius: var(--bulma-radius);
  }

  &.is-48x48 {
    &.is-padded {
      padding: var(--spacing-sm);
      background-color: var(--bulma-white-ter);

      .theme-dark & {
        background-color: hsl(221, 14%, 14%);
      }
    }
  }

  &.is-skeleton {
    background-color: var(--bulma-skeleton-background) !important;
  }

  &.is-rounded {
    border-radius: 100%;
  }

  @each $pair in base.$aspect-ratios {
    $w: nth($pair, 1);
    $h: nth($pair, 2);

    &.#{base.$class-prefix}is-#{$w}by#{$h} {
      aspect-ratio: #{$w} / #{$h};

      img,
      .#{base.$class-prefix}has-ratio {
        @include base.overlay;
        height: 100%;
        width: 100%;
      }
    }
  }

  // Sizes
  @each $dimension in $dimensions {
    $rem-value: calc($dimension / 14);

    &.#{base.$class-prefix}is-#{$dimension}x#{$dimension} {
      height: $rem-value * 1rem;
      flex: 0 0 $rem-value * 1rem;
      width: $rem-value * 1rem;
    }
  }
}