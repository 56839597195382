.icon-card {
  border-radius: var(--bulma-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bulma-white-ter);

  &.is-small {
    width: 2rem;
    height: 2rem;
  }

  &.is-normal {
    width: 3.5rem;
    height: 3.5rem;
  }

  &.is-medium {
    width: 4.75rem;
    height: 4.75rem;
  }

  &.is-large {
    width: 6.5rem;
    height: 6.5rem;
  }
}