@use "src/scss/base";
@use "bulma/sass/elements/icon" with (
  $icon-dimensions-small: 1.2rem
);

.icon {
  transition-property: color;
  transition-duration: base.$speed !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}