@use "src/scss/base" as base;

.fixture-preview {
  min-width: 250px;

  .preview-header {
    $minus-margin: calc(-1 * var(--bulma-box-padding));
    margin: $minus-margin $minus-margin 0 $minus-margin;

    .tag:first-of-type {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    };

    .tag:last-of-type {
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
