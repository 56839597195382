@use "src/scss/base" as base;

.teams-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacing-md);
  padding-right: var(--spacing-sm) !important;

  &__image-title {
    display: flex;
    align-items: center;
    min-width: 0; // Allows truncation of children
    flex: 1;
    gap: var(--spacing-xs);
    overflow: hidden; // Necessary for ellipsis truncation

    @include base.desktop {
      gap: var(--spacing-sm);
    }
  }

  &__images {
    display: flex;
    flex-shrink: 0;

    .image {
      border: 3px solid var(--bulma-body-background-color);

      &:first-child {
        margin-right: calc(-1 * var(--spacing-sm));
      }
    }
  }

  &__title {
    font-weight: 600;
    line-height: 1.1;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.is-skeleton {
      margin-bottom: 0.25rem;
    }

    @include base.mobile {
      width: 99%;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    min-width: 0;
    overflow: auto;
    white-space: nowrap;
    text-overflow: ellipsis;

    // For each info item inside, ensure truncation if needed
    .teams-info-item {
      flex-shrink: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .live-status-badge {
    margin-left: -0.3rem;
    margin-right: 0.2rem;
  }

  &__score-container {
    flex-shrink: 0; // Ensures score stays visible
  }
}

.teams-info-item.is-skeleton {
  height: 1rem !important;
}

.min-w-0 {
  min-width: 0;
}

.flex-1 {
    flex: 1;
}