@use "bulma/sass/elements/tag";

.tag {
  transition: all var(--bulma-speed) var(--bulma-easing);

  &.is-skeleton {
    background: var(--bulma-skeleton-background) !important;
  }
}

.tags {
  margin-bottom: 0 !important;
}