@use "src/scss/base" as base;
@use "bulma/sass/components/dropdown" with (
  $dropdown-content-padding-bottom: 0,
  $dropdown-content-padding-top: 0,
  $dropdown-content-shadow: base.$box-shadow,
);

.dropdown {
  &-menu {
    border: 1px solid var(--bulma-border);
    border-radius: var(--bulma-radius);
    display: block !important;
    padding: 0;
    z-index: 100;
    box-shadow: base.$box-shadow;
    background: base.$white;
  }

  &-item {
    line-height: 2.4;
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 0.375rem 1.2rem;

    &.is-single {
      padding: 1.2rem;
    }

    &:hover {
      background: base.$white-ter;
    }

    &:active {
      background: base.$grey-lightest;
    }
  }

  &-content {
    overflow: hidden;
  }

  &-divider {
    margin: 0 !important;
    background-color: var(--bulma-border);
  }
}