@use "src/scss/base" as base;

.statistics-overview {
  .statistics-overview-bottom-progress {
    @include base.mobile {
      flex: 0 0 100%;
      order: -1;
      margin-bottom: 10px;
      padding-top: 10px;
    }
  }

  .statistics-overview-bottom-stats-wrapper {
    @include base.mobile {
      gap: 0 !important;
      flex-wrap: wrap;
      justify-content: space-between !important;
    }
  }

  @include base.mobile {
    &__icon-stats {
      flex: 0 0 50%;

      &:first-of-type {
        order: 2;
      }

      &:last-of-type {
        order: 3;
      }
    }
  }

  &__icon-stats {
    display: flex;
    flex: 1;

    &__item {
      padding: 0 5px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.circular-statistics {
  position: relative;

  &__label {
    margin-bottom: 4px;
    text-align: center;
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -13px;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  &__label {
    flex: 0 0 100%;
  }

  &__progress {
    width: 3.5rem;
    height: 3.5rem;
    flex: 0 0 3.5rem;
    position: relative;
  }
}

.key-statistics-progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  &__item {
    height: 10px;
    background-color: var(--bulma-white-ter);
    position: relative;
    border-radius: 5px;

    &__inner {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: var(--bulma-body-color);
    }

    &:first-child {
      margin-right: 3px;
    }

    &:first-child &__inner {
      right: 0;
    }

    &--active {
      opacity: 1;
    }
  }
}

.statistics-overview-bottom-progress {
  text-align: center;
  width: 300px;
  flex: 0 0 300px;
}

.general-progress-bar {
  &__bar {
    border-radius: var(--bulma-radius);
    overflow: hidden;


    &__item {
      height: 0.75rem;
      background-color: var(--bulma-white-ter);
    }
  }
}

.statistics-overview-all {
  margin-top: var(--spacing-xl) !important;
  max-width: 100%;
  width: 410px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0 var(--spacing-lg) !important;

  &-wrapper {
    border-top: 1px solid var(--bulma-border);
    margin-top: -1.75rem;
  }
}

.statistics-overview-columns {
  @include base.mobile {
    overflow: auto;
  }
}