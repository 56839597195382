@use "src/scss/base";

.list-media {
  position: relative;

  &__badge {
    position: absolute;
    z-index: 1;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: base.$white-ter;
    border: 2px solid white;
    border-radius: 100%;
    left: -0.5rem;
    top: -0.5rem;
    font-size: 0.75rem;
  }
}