@use "src/scss/base";

.bankroll-input {
  min-width: unset !important;
  width: 18rem !important;
  height: 3rem !important;
  padding-right: 2rem !important;
}

.bankroll-input-delete {
  position: absolute !important;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.page-template-wrapper {
  max-width: 1440px;
  margin-left: auto;
  padding: 0 34px 34px;
  max-height: 100vh;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  @include base.touch {
    padding: 5rem 0 4.875rem;
    max-height: unset;
    min-height: 100dvh;
  }
}

.template-header {
  padding: 20px 0 !important;

  @include base.touch {
    padding: var(--spacing-xs) var(--spacing-md) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--bulma-body-background-color);
    box-shadow: base.$box-shadow;

    .menu-toggle {
      display: none;
    }
  }

  &__logo {
    display: flex;
    gap: var(--spacing-sm);
    color: var(--bulma-text);
    align-items: center;
    max-width: 4.2rem;
    overflow: hidden;
    transition: max-width var(--bulma-speed) var(--bulma-easing);
    cursor: pointer;
    user-select: none;

    &.is-active {
      max-width: 215px;
    }

    @include base.touch {
      max-width: unset !important;
    }

    .image {
      width: 4rem;
      height: 4rem;
      flex: 0 0 4rem;
      background: var(--app-background);
      border-radius: 100%;
      padding: 5px;
    }

    .logo-text {
      font-weight: var(--bulma-weight-semibold);
      white-space: nowrap;
      font-size: 1.2rem;

      @include base.touch {
        display: none;
      }

      span {
        background: linear-gradient(89deg, #004D4D 0%, #00B3B3 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

.page-template {
  display: flex;
  background: var(--bulma-body-background-color);
  min-height: 0;
  flex: 1;

  @include base.touch {
    flex: auto;
    overflow: unset;
  }

  &__content {
    flex: 1;
    padding: 50px;
    border-radius: 20px;
    background: var(--app-background);
    min-width: 0;
    position: relative;

    @include base.touch {
      padding: var(--spacing-xxl) var(--spacing-md);
      overflow: unset;
      border-radius: 0;
    }
    @include base.mobile {
      &.has-free-plan {
        padding-top: 6rem;
      }
    }

    &__inner {
      max-height: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      @include base.widescreen {
        padding-right: 10px;
      }

      @include base.touch {
        overflow: unset;
        height: auto;
        max-height: unset;
      }

      /* Custom scrollbar styling */
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #FFFDF2; /* Light grey background */
        border-radius: 5px; /* Rounded corners */
      }

      &::-webkit-scrollbar-thumb {
        background: var(--Main-Neutral, #F5F5F5);
        border-radius: 5px; /* Rounded corners */
        border: 2px solid white; /* Matches track background for a smoother look */
      }

      scrollbar-width: thin;
      scrollbar-color: #F5F5F5 #FFFDF2;
    }
  }
}

.template-nav {
  padding: var(--spacing-lg) 0 !important;
  max-width: 4.2rem;
  transition: max-width var(--bulma-speed) var(--bulma-easing);
  width: 100%;

  @include base.touch {
    z-index: 10;
    flex-direction: row !important;
    gap: 0 !important;
    justify-content: space-evenly;
    background: var(--bulma-body-background-color);
    order: 2;
    margin: 0 !important;
    max-width: unset !important;
    padding: 0 var(--spacing-md) !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: base.$box-shadow-invert;
  }

  &.is-active {
    max-width: 230px;
  }

  &__item {
    color: var(--bulma-body-color);
    font-size: 14.4px;
    font-weight: var(--bulma-body-weight);
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
    border-top-left-radius: var(--bulma-radius-large);
    border-bottom-left-radius: var(--bulma-radius-large);
    user-select: none;
    transition: all var(--bulma-speed) var(--bulma-easing);
    white-space: nowrap;

    &.disabled {
      color: var(--bulma-text-weak);
      cursor: not-allowed;
      background-color: var(--active-background) !important;
      position: relative;

      &.is-closed {
        .locked-badge {
          transform: translateY(-2.65rem) translateX(-1.5rem);
        }
      }

      .locked-badge {
        transition: all var(--bulma-speed) var(--bulma-easing);
        width: 2rem;
        height: 2rem;
        background: white;
        border-radius: 100%;
        padding: var(--spacing-xs);
        border: 1px solid var(--bulma-border);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: var(--spacing-md);

        .icon {
          width: 100%;
          height: 100%;
        }

        @include base.touch {
          transform: translateY(-3rem) translateX(0.25rem) !important;
        }
      }

      &:active {
        background-color: var(--active-background) !important;
      }
    }

    &:not(.is-active) {
      &:hover {
        background-color: var(--hover-background)
      }

      &:active {
        background-color: var(--active-background)
      }
    }

    &.is-active {
      background: var(--app-background);
      font-weight: bold;
      padding: 20px;
    }

    @include base.touch {
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-xxs);
      padding: var(--spacing-md) var(--spacing-sm);
      width: 100%;
      border-radius: 0;
      flex: 0 0 25%;

      &.is-active {
        background: var(--app-background);
        font-weight: bold;
        padding: var(--spacing-md) var(--spacing-sm);
      }

      .text {
        font-size: 0.85rem;
      }
    }
  }
}