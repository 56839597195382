@use "src/scss/base" as base;

.league-select {
  position: relative;
  z-index: 10;
}

.league-tag-delete {
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--bulma-danger-90) !important;
  }

  &:active {
    background: var(--bulma-danger-85) !important;
  }
}

.custom-select-option {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 0.75rem 1rem;
  font-size: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid base.$border;
  }

  &:hover {
    background: var(--bulma-white-bis);
    color: var(--bulma-text);
  }
}

.league-value-container {
  padding: var(--spacing-md);
  flex: 1;
}