.fixture-date-calendar {
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    height: 30px;
  }
}

.overflow-hidden {
  overflow: hidden;
}