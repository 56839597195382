@use "src/scss/base" as base;

.free-plan-indicator {
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;

  &__progress {
    height: 0.5rem;
    width: 100%;
    background: var(--bulma-white);
    border-radius: 4px;
    overflow: hidden;

    &__inner {
      height: 100%;
      background: var(--bulma-white-ter);
    }
  }
}

.free-plan-indicator-wrapper {
  position: relative;
  border-radius: var(--bulma-radius);
  display: flex;
  padding: var(--spacing-sm);
  gap: var(--spacing-md);
  min-width: 25rem;
  justify-content: stretch;

  @include base.mobile {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.is-success {
    background: var(--bulma-success-light);

    .free-plan-indicator__progress__inner {
      background: var(--bulma-success);
    }
  }

  &.is-danger {
    background: var(--bulma-danger-light);

    .free-plan-indicator__progress__inner {
      background: var(--bulma-danger);
    }
  }

  &.is-warning {
    background: var(--bulma-warning-light);

    .free-plan-indicator__progress__inner {
      background: var(--bulma-warning);
    }
  }
}