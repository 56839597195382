@use "src/scss/base";

.prediction-preview-content {
  white-space: normal;
}

.fixture-selector {
  display: flex;

  &__calendar {
    display: flex;
    justify-content: center;
  }

  &__list {
    width: 100%;
    max-height: 270px;
    overflow: auto;

    @include base.mobile {
      max-height: unset;
    }
  }

  @include base.mobile {
    flex-direction: column;
  }
}

.fixture-row {
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: base.$white-ter;
  }

  &.highlighted-row {
    background: base.$primary;
    color: base.$text;
    border-radius: 5px;

    p {
      color: base.$text !important;
    }
  }
}