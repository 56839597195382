@use "src/scss/base" as base;
@use "bulma/sass/elements/title" with (
  $title-weight: var(--bulma-weight-semibold),
);

.title {
  &.icon-text {
    display: flex;
    align-items: flex-end;
  }

  &.no-wrap {
    white-space: nowrap;
  }
}