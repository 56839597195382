@use "src/scss/base" as base;

$shadow-size-percentage: 0.6;
$pulse-duration: 1.5s;


$badge-sizes: (
  "small": 1.25rem,
  "normal": 1.5rem,
  "medium": 2rem,
  "large": 3rem,
);

@mixin pulse($inner-shadow, $color, $animation-name) {
  @keyframes #{$animation-name} {
    0% {
      box-shadow: 0 0 0 0 rgba($color, 0.7);
    }
    70% {
      box-shadow: 0 0 0 $inner-shadow * 1.5 rgba($color, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba($color, 0);
    }
  }
}



.status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  &__inner {
    border-radius: 100%;
  }

  @each $size, $size-value in $badge-sizes {
    $inner-size: $size-value * $shadow-size-percentage;
    $inner-shadow: calc(($size-value - $inner-size) / 2);

    &.is-#{$size} {
      width: $size-value;
      height: $size-value;
      flex: 0 0 $size-value;

      .status-badge__inner {
        width: $inner-size;
        height: $inner-size;
        flex: 0 0 $inner-size;
      }

      @each $color-name, $pair in base.$colors {
        $color: nth($pair, 1);

        &.is-#{$color-name} {
          .status-badge__inner {
            background: $color;
            box-shadow: 0 0 0 $inner-shadow rgba($color, 0.2);

            &.is-pulse {
              $animation-name: pulse-#{$size}-#{$color-name};
              @include pulse($inner-shadow, $color, $animation-name);
              animation: $animation-name $pulse-duration infinite ease-in-out;
            }
          }
        }
      }
    }
  }
}