@use "bulma/sass/helpers/typography";
@use "bulma/sass/helpers/color";

.text {
  &.secondary {
    color: var(--bulma-text-weak)
  }

  &.is-narrow {
    line-height: 1.1;
  }

  &.icon-text {
    align-items: center;
  }

  &.is-no-wrap {
    white-space: nowrap;
  }

  &.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}