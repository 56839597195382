.calendar-slider {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  //gap: var(--spacing-xs);

  .swiper {
    display: grid;
    padding-top: 4px;
    padding-right: 4px;
  }

  .swiper-slide {
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }

  .swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
  }

  .slick-initialized.slick-slider {
    overflow: hidden;
  }

  .swiper-container {

  }
}