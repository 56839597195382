@use "src/scss/base";

.custom-tip-header {
  p {
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tags {
    flex-wrap: nowrap;
  }
}

.article-card {
  border: 1px solid base.$white;
  transition: border 0.2s ease;
  border-radius: 6px !important;

  &.is-active {
    border: 1px solid base.$primary;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.3), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  }

  .card-image {
    img {
      object-fit: cover;
    }
  }
}