@use "src/scss/base";

.odds-message {
  border: 1px solid var(--bulma-border);
  border-radius: var(--bulma-radius);
  overflow: hidden;
  
  &__header {
    padding: var(--spacing-xs) var(--spacing-lg) !important;
    cursor: pointer;
    background: var(--bulma-white-bis);
    transition: background-color var(--bulma-speed) var(--bulma-easing);

    &:active {
      background: var(--bulma-white-ter);
    }
  }

  &__body {
    background-color: var(--bulma-body-background-color);
  }

  &__body {
    max-height: 0;
    transition: max-height var(--bulma-speed) var(--bulma-easing);
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 3px;
      z-index: 1;
      padding-bottom: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--bulma-border);
      border-radius: var(--bulma-radius);
    }

    .table {
      tr {
        td:first-child {
          padding-left: 18px;
        }
      }
    }

    &--active {
      max-height: 150px;
      border-top: 1px solid var(--bulma-border) !important;
    }
  }
}