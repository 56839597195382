.tooltip {
  background: var(--bulma-text);
  color: var(--bulma-white-ter) !important;
  padding: 10px 13px;
  border-radius: var(--bulma-radius);
  font-size: 12px;
  max-width: 300px;
  box-shadow: var(--bulma-dropdown-content-shadow);
  position: relative;

  * {
    color: var(--bulma-white-ter) !important;
  }
}

[role="tooltip"] {
  z-index: 15;
}