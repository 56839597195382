.calendar-day {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--bulma-radius);
  background: var(--bulma-body-background-color);
  padding: var(--spacing-sm) var(--spacing-lg);
  user-select: none;
  cursor: pointer;
  transition: all var(--bulma-speed) var(--bulma-easing);

  .title {
    margin-bottom: 0;
  }

  &.is-active {
    background-color: var(--bulma-primary);
  }

  &:hover:not(.is-active) {
    background-color: var(--bulma-primary-light);
  }

  &:active:not(.is-active) {
    background-color: var(--bulma-primary-85);
  }

  &.is-today {
    position: relative;

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--bulma-danger);
      position: absolute;
      top: -3px;
      right: -3px;
    }
  }
}