@use "src/scss/base";

.button-select {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;

  @include base.touch {
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}