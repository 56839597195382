@use "src/scss/base" as base;

$datepicker__background-color: base.$white-ter !default;
$datepicker__border-color: base.$border !default;
$datepicker__highlighted-color: base.$primary !default;
$datepicker__holidays-color: base.$warning !default;
$datepicker__muted-color: base.$grey-dark !default;
$datepicker__selected-color: base.$dark !default;
$datepicker__text-color: base.$text !default;
$datepicker__header-color: base.$text !default;
$datepicker__navigation-disabled-color: base.$grey-darker;
$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.17rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: base.$family-primary;
$datepicker__item-size: 1.7rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-button-size: 2.5rem;

@import "react-datepicker/src/stylesheets/datepicker";

.react-datepicker {
  &__day {
    transition: all base.$easing base.$speed;

    &--today {
      color: base.$danger !important;
    }

    &--disabled {
      color: base.$grey-light;
    }
  }
}

.date-picker-box {
  position: relative;
  z-index: 2;

  @include base.from(base.$desktop) {
    position: sticky;
    top: 0;
  }
}