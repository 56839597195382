@use "src/scss/base" as base;
@import "rc-slider/assets/index.css";

@mixin slider-variant($color-name) {
  &.is-#{$color-name} {
    .rc-slider-handle-dragging {
      box-shadow: 0 0 0 5px var(--bulma-#{$color-name}-85);
    }

    .rc-slider-track {
      background-color: var(--bulma-#{$color-name}-70);
      transition: all var(--bulma-speed) var(--bulma-easing);
    }

    .rc-slider-handle {
      transition: all var(--bulma-speed) var(--bulma-easing);
      border-color: var(--bulma-#{$color-name}-70);

      &:focus-visible,
      &:hover {
        box-shadow: 0 0 0 3px var(--bulma-#{$color-name}-85);
      }

      &:active {
        box-shadow: 0 0 0 5px var(--bulma-#{$color-name}-85);
      }
    }
  }
}

.rc-slider {
  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px base.$dark;
  }

  .rc-slider-track {
    transition: all var(--bulma-speed) var(--bulma-easing);
    background-color: base.$grey-dark;
  }

  .rc-slider-handle {
    transition: all var(--bulma-speed) var(--bulma-easing);
    border-color: base.$grey-dark;

    &:focus-visible,
    &:hover {
      box-shadow: 0 0 0 3px rgba(base.$dark, 0.5);
      border-color: base.$grey-dark;
    }

    &:active {
      border-color: base.$grey-dark;
      box-shadow: 0 0 0 5px rgba(base.$dark, 0.6);
    }
  }

  @include slider-variant(success);
  @include slider-variant(warning);
  @include slider-variant(info);
  @include slider-variant(danger);
  @include slider-variant(text);
  @include slider-variant(dark);
  @include slider-variant(grey);
}