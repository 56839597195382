@use "~bulma/sass/utilities/functions" as fn;

$yellow: #FFD700;
$beige: #EADAB8;
$input-icon-focus-color: $yellow;
$box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
$box-shadow-invert: rgba(0, 0, 0, 0.1) 1px 0px 2px 0px;
$grey: #dfe2e7;
$dark: hsl(221, 14%, 21%);

@forward "bulma/sass/utilities/initial-variables" with (
  $family-sans-serif: "Poppins",
  $block-spacing: 0rem,
);
@forward "bulma/sass/utilities/derived-variables" with (
  $primary: $beige,
  $custom-colors: ("grey": ($grey, fn.bulmaFindColorInvert($grey)), "dark": ($dark, fn.bulmaFindColorInvert($dark))),
);
@forward "bulma/sass/utilities/mixins";