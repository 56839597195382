@use "src/scss/base";

.login-layout {
  padding: var(--spacing-xxl) 0;
  background: var(--app-background);
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__logo {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  &__content {
    padding: var(--spacing-xxl) 0;
    display: flex;
    align-items: center;
  }

  &__box {
    width: 25rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  &__box-help {
    margin-bottom: var(--spacing-xxl);
  }

  &__legals {
    margin-top: auto;
  }
}