@use "bulma/sass/elements/table" with (
  $table-cell-border-color: transparent,
  $table-cell-heading-color: var(--bulma-text-weak),
);

:root {
  --bulma-table-cell-heading-color: var(--bulma-text-weak);
  --bulma-table-cell-border-width: 0px;
  --bulma-table-cell-border-color: transparent;
}

.table {
  .is-fullwidth {
    width: 100%;
  }

  th {
    font-weight: var(--bulma-weight-light);
    white-space: nowrap;
    font-size: var(--bulma-size-6);
  }

  tr {
    td,
    th {
      vertical-align: middle;

      &.is-vertical-align-top {
        vertical-align: top;
      }

      &.is-gapless {
        padding: 0;

        &:last-child {
          padding-right: 0.75em;
        }
      }

      &.is-vertical-align-middle {
        vertical-align: middle;
      }

      &.is-vertical-align-bottom {
        vertical-align: bottom;
      }

      &:first-child {
        border-top-left-radius: var(--bulma-radius);
        border-bottom-left-radius: var(--bulma-radius);
      }

      &:last-child {
        border-top-right-radius: var(--bulma-radius);
        border-bottom-right-radius: var(--bulma-radius);
      }
    }
  }
}