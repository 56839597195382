@use "src/scss/base" as base;

@use "bulma/sass/elements/box" as box with (
  $box-padding: var(--spacing-md),
  $box-shadow: base.$box-shadow,
  $box-radius: base.$radius,
);

.box {
  padding: 0;

  &.is-bordered {
    border: 1px solid var(--bulma-border);
  }

  &__body {
    padding: box.$box-padding;

    &.has-header {
      border-top: 1px solid var(--bulma-border);
    }

    &:empty {
      display: none;
    }
  }

  &__header {
    padding: box.$box-padding;

    &.is-clickable {
      transition: background-color base.$speed base.$easing;

      &:hover {
        background-color: base.$white-bis;
      }

      &:active {
        background-color: base.$white-ter;
      }
    }
  }

  &__footer {
    padding: box.$box-padding;
    border-top: 1px solid var(--bulma-border);
  }

  &.is-fullwidth {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 0 !important;
  }
}