@use "src/scss/base" as base;

.stake-input {
  min-width: 8rem;

  @include base.mobile {
    min-width: 7rem;
  }

  @include base.desktop {
    min-width: 15rem;
  }
}

.odds-input {
  width: 4rem;
}