@use "src/scss/base" as base;
@use "bulma/sass/components/tabs";

.tabs:not(:last-child) {
  margin-bottom: 0 !important;;
}

.tabs {
  --bulma-tabs-toggle-link-active-background-color: var(--bulma-text-light);
  --bulma-tabs-toggle-link-active-border-color: var(--bulma-text-light);
  --bulma-tabs-toggle-link-active-color: var(--bulma-primary-invert);

  --bulma-tabs-link-active-border-bottom-color: var(--bulma-primary-invert);
  --bulma-tabs-link-active-color: var(--bulma-primary-invert);

  .theme-dark & {
    --bulma-tabs-toggle-link-active-background-color: var(--bulma-primary);
    --bulma-tabs-toggle-link-active-border-color: var(--bulma-primary);
    --bulma-tabs-toggle-link-active-color: white;

    --bulma-tabs-link-active-border-bottom-color: white;
    --bulma-tabs-link-active-color: white;
  }

  .info-link {
    color: var(--bulma-link);
    text-decoration: underline;
    font-size: 0.85rem;
    border-bottom: 0;
    margin-left: auto;

    :hover {
      background-color: transparent;
    }
  }

  &.is-toggle {
    ul {
      gap: var(--spacing-md);

      li {
        a {
          border-radius: var(--bulma-radius);
        }
      }
    }
  }
}