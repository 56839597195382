@use "src/scss/base";
@use "bulma/sass/components/pagination";


.pagination {
  width: 100%;
  max-width: none;
  justify-content: space-between;
}

.pagination-list {
  order: -1;

  .button {
    margin: 0 var(--spacing-xxs);
  }
}