@use "bulma/sass/form/input-textarea";

.control {
  &.has-icons-left {
    .input {
      padding-left: 3rem !important;
    }

    .icon.is-left {
      font-size: 0.6rem;
      color: var(--bulma-text-weak);
      top: 50%;
      transform: translateY(-50%);
      left: 0.8rem !important
    }
  }
}

.input {
  &.is-grey {
    background: var(--bulma-white-ter);
    border-color: var(--bulma-white-ter);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}