@use "src/scss/base";

.chat-window {
  padding: 20px;
  border-radius: base.$radius-large;
  border: 1px solid base.$primary;
  overflow: hidden;
  background: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

  &__footer {
    margin: -20px;
    padding: 20px;
    margin-top: 0;
    border-top: 1px solid base.$primary;
    background: base.$white-bis;
    z-index: 1;
  }

  &__body {
    max-height: 600px;
    overflow-y: scroll;
    padding: 20px 0;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 3px;
      z-index: 1;
      padding-bottom: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: base.$border;
      border-radius: base.$radius-small;
    }
  }

  &__header {
    margin: -20px;
    padding: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid base.$primary;
    background: base.$white-bis;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    @include base.mobile {
      flex-direction: column;
    }

    .steps {
      flex: 1;
      margin-bottom: 0 !important;

      @include base.mobile {
        margin-bottom: 15px !important;
      }
    }
  }
}

.chat-message {
  &:not(:last-child) {
    margin-bottom: 50px;
  }

  .tabs {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  &__avatar {
    background: base.$white-ter;
    border: 1px solid base.$primary;
    border-radius: 100%;
  }

  &__title {
    font-weight: bold;
    font-size: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  &--user &__header {
    flex-direction: row-reverse;
  }

  &--user .type-loader {
    text-align: right;
  }

  &--system &__content {
      background: base.$white-ter !important;
  }

  &__content {
    background: base.$white-ter;
    padding: 20px;
    border-radius: base.$radius-large;
    max-width: calc(100% - 200px);
    width: fit-content;
    min-width: 200px;
    position: relative;
    margin-bottom: 0 !important;

    @include base.mobile {
      max-width: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    color: base.$grey-light;
    gap: 3px;
    font-size: 12px;
    margin-top: 3px;
    
    svg {
      cursor: pointer;
    }
  }

  &--user &__footer {
    justify-content: flex-end;
  }

  &--user &__content {
    margin-left: auto;
  }

  &__user-avatar {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
  }
}

.type-loader {
  text-align: left;
}

.type-loader span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 10px;
  animation: type-loader 0.8s infinite alternate;

  &:not(:last-child) {
    margin-right: 2px;
  }
}

.type-loader span:nth-of-type(2) {
  animation-delay: 0.2s;
}

.type-loader span:nth-of-type(3) {
  animation-delay: 0.6s;
}

@keyframes type-loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

.chat-send-field {
  width: 100%;

  .control:first-child {
    flex: 1;
  }
}

.auto-play-field {
  margin-left: 15px;

  @include base.mobile {
    margin-left: 0;
  }
}
.messages-wrapper {
  margin-bottom: 15px;
}

.tip-collection {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}