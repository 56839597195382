.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: var(--bulma-radius-rounded);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bulma-primary-95);
  color: var(--bulma-primary-light-invert);
  border: 2px solid var(--bulma-primary-90);
  transition: all var(--bulma-speed) var(--bulma-easing);
  cursor: pointer;
  user-select: none;

  &:hover {
    background: var(--bulma-primary-95);
  }

  &:active {
    background: var(--bulma-primary-90);
  }
}