.divider {
  background: var(--bulma-border);
  position: relative;

  &.is-horizontal {
    width: 100%;
    height: 1px;
  }

  &.is-vertical {
    height: 100%;
    width: 1px;
  }

  span {
    background: white;
    padding: var(--spacing-xxs) var(--spacing-sm);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}