@use "src/scss/base";
@use "bulma/sass/elements/notification" with (
  $notification-padding: var(--spacing-md),
);

.notification {
  &.is-light {
    @each $color-name, $pair in base.$colors {
      $color: nth($pair, 1);

      &.is-#{$color-name} {
        background-color: var(--bulma-#{$color-name}-95) !important;
        border: 1px solid $color;
      }
    }
  }
}