@use "src/scss/base";
@use "bulma/sass/form/input-textarea";

.control {
  &.has-icons-left {
    .icon.is-left {
      font-size: 0.6rem;
      color: var(--bulma-text-weak);
      top: 50%;
      transform: translateY(-50%);
      left: 0.5rem !important;
    }
  }
}

.input {
  box-shadow: none !important;

  &:focus {
    border-color: var(--bulma-grey) !important;
    box-shadow: none !important;

    + .icon {
      color: var(--bulma-text) !important;
    }
  }

  &.is-wide {
    min-width: 8rem;
    max-width: 8rem;
  }

  &.is-grey {
    background: var(--bulma-white-ter);
    border-color: var(--bulma-white-ter);
    box-shadow: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}