.key-statistics-group {
  &:not(:last-child) {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 2px solid var(--bulma-border);
  }
}


.statistics-print-container,
.totalStatistics-print-container,
.homeAwayStatistics-print-container,
.h2hStatistics-print-container {
  .show-more-button-row {
    display: none;
  }

  .box {
    width: 660px;

    &__header__icons {
      display: none;
    }
  }

  .tabs {
    display: none;
  }

  .progress {
    display: none;
  }

  .statistics-item__title {
    margin-bottom: -18px;
  }

  .statistics-item__progress {
    //font-weight: bold;

    &--highlight {
      font-weight: 600;
    }
  }

  .statistics-item {
    &:not(:last-child) {
      padding-bottom: 7.5px;
      margin-bottom: 7.5px !important;
      border-bottom: 1px solid hsl(0, 0%, 86%);
    }
  }

  .print-teams-header {
    display: flex;
  }
}

.print-teams-header {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 10px;

  border-bottom: 0.1rem solid hsl(0, 0%, 86%);

  &__item {
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: var(--bulma-body-color);
      font-weight: bold;
    }

    &:first-of-type {
      p {
        margin-left: 8px;
      }
    }

    &:last-of-type {
      flex-direction: row-reverse;

      p {
        margin-right: 8px;
      }
    }
  }
}