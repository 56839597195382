@use "bulma/sass/form/input-textarea";

.textarea {
  &:focus {
    border-color: var(--bulma-grey) !important;
    box-shadow: none !important;

    + .icon {
      color: var(--bulma-text) !important;
    }
  }
}