@use "src/scss/base" as base;

.top-performers-list {
  overflow-y: auto;

  @include base.until(base.$widescreen) {
    .column {
      min-width: 25rem;
    }
  }

  @include base.touch {
    .column {
      min-width: 85%;
      flex: 0 0 85%;
    }
  }
}