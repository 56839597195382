@use "src/scss/base" as base;

.reasoning-dropdown-content {
  max-width: 30rem;
  padding: var(--spacing-md);
}

.category-row {
  @media screen and (max-width: 389px) {
    max-width: 6.5rem;
  }
}

.tip-name-row {
  @media screen and (max-width: 389px) {
    max-width: 6.5rem;
  }
}

.is-text-dark {
  color: var(--bulma-body-color);
}