.badges-wrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .form-badge {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    color: white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}

