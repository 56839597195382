@use "src/scss/base" as base;

/* BillingCycleToggle.module.scss */

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.5rem; /* Equivalent to pb-6 */
}

.button {
  cursor: pointer;
  font-size: 1.125rem; /* Equivalent to text-lg */
  letter-spacing: 0.05em; /* Equivalent to tracking-wide */
  background: none;
  border: none;
  padding: 0.5rem 0.1rem;
  transition: color 0.3s;

  &.active {
    font-weight: 600; /* Equivalent to font-semibold */
    color: var(--bulma-body); /* Equivalent to dark:text-neutral-200 */
  }

  &.inactive {
    color: #9ca3af; /* Equivalent to text-gray-400 */
  }
}

.toggleLabel {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 0 1rem;
}

.toggleInput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSwitch {
  position: relative;
  width: 3rem; /* Equivalent to w-11 */
  height: 1.5rem; /* Equivalent to h-6 */
  background-color: #d1d5db; /* Equivalent to bg-gray-300 */
  border-radius: 9999px;
  transition: background-color 0.3s;

  &::after {
    content: '';
    position: absolute;
    left: 0.125rem; /* Equivalent to left-[2px] */
    top: 0.125rem; /* Equivalent to top-[2px] */
    width: 1.25rem; /* Equivalent to w-5 */
    height: 1.25rem; /* Equivalent to h-5 */
    background-color: #374151; /* Equivalent to after:bg-neutral-700 */
    border: 1px solid #374151; /* Equivalent to after:border-neutral-700 */
    border-radius: 9999px;
    transition: transform 0.3s, background-color 0.3s, border-color 0.3s;
  }

  /* Checked state */
  .toggleInput:checked + & {
    background: base.$primary;
  }

  .toggleInput:checked + &::after {
    transform: translateX(1.5rem); /* Equivalent to peer-checked:after:translate-x-full */
    background-color: #1f2937; /* Equivalent to dark:after:bg-neutral-800 */
    border-color: #374151; /* Equivalent to after:border-neutral-700 */
  }
}
