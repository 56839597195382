.single-tip {
  padding: var(--spacing-xs) 0 !important;
  position: relative;

  &__badge {
    padding: 0 var(--spacing-xs);
    display: flex;
    align-items: center;

    &:before {
      content: "";
      background: var(--bulma-grey-lighter);
      width: 1px;
      height: 100%;
      position: absolute;
      left: 13px;
      top: 0;
      bottom: 0;
      z-index: 0;
    }

    div {
      position: relative;
      z-index: 1;
      width: 12px;
      height: 12px;
      background: var(--bulma-grey-lighter);
      border-radius: 100%;
    }

    &.winner {
      div {
        background: var(--bulma-success);
      }
    }

    &.lost {
      div {
        background: var(--bulma-danger);
      }
    }
  }
}