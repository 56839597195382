.match-details-drawer {
  &__tab-list {
    margin-bottom: calc(-1 * var(--spacing-xl) - 1px)
  }
}

.match-details-teams-header-wrapper {
  flex: 1;
  min-width: 0;

  .teams-header {
    padding-right: 0 !important;
  }
}