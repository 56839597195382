@use "src/scss/base" as base;
@use "bulma/sass/elements/button" with (
  $button-ghost-color: base.$text-weak,
  $button-ghost-hover-color: base.$text
);

.button {
  box-shadow: none;

  &.is-rounded {
    .icon:first-child:last-child {
      margin-top: calc(var(--bulma-button-padding-vertical) / 2);
      margin-bottom: calc(var(--bulma-button-padding-vertical) / 2);
    }
  }
}