@use "src/scss/base" as base;

.telegram-join-button {
  background: #0088cc;
  color: #FFFFFF;
  padding-left: 1.3rem;

  &:hover {
    background: #0072b5;
  }

  &:active {
    background: #005580;
  }
}

.telegram-image-wrapper {
  width: 29rem;
  min-width: 30rem;
  margin-right: -8rem;
  margin-left: -1rem;
  margin-top: -5rem;
  margin-bottom: -9rem;
  overflow: hidden;

  @include base.mobile {
    width: calc(100% + 5rem);
    display: flex;
    justify-content: center;
    max-width: 420px;
    overflow: hidden;

    margin: 0 auto;
    margin-bottom: -10rem;
    margin-top: -5rem;

    img {
      position: relative;
      right: -2rem;
    }
  }
}

.telegram-modal {
  .modal-card-body {
    overflow: hidden;

    @include base.mobile {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.telegram-join-content {
  padding-top: 1.5rem;
}

.telegram-modal-content {
  @include base.mobile {
    flex-direction: column !important;
  }
}