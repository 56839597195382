@use "src/scss/base" as base;

.checkbox {
  display: inline-flex;
  gap: var(--spacing-xs);
  position: relative;

  a {
    position: relative;
    z-index: 1;
  }

  &.is-centered {
    align-items: center;
  }

  .icon {
    color: transparent;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .checkbox-toggle {
    width: 1.5rem;
    flex: 0 0 1.5rem;
    height: 1.5rem;
    border-radius: var(--bulma-radius-small);
    border: 1px solid var(--bulma-border);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color, var(--bulma-speed), var(--bulma-easing);
  }

  input:checked + .checkbox-toggle {
    .icon {
      color: var(--bulma-body);
    }
  }

  @each $color-name, $pair in base.$colors {
    $color: nth($pair, 1);

    &.is-#{$color-name} {
      .checkbox-toggle {
        border-color: $color;
      }

      input:checked + .checkbox-toggle {
        .icon {
          color: var(--bulma-primary);
        }
      }
    }
  }

  &:hover {
    .checkbox-toggle {
      border-color: var(--bulma-grey);
      color: var(--bulma-grey);
    }
  }

  &:active,
  input:checked + {
    .checkbox-toggle {
      border-color: var(--bulma-body);
    }
  }
}