.progress-bar {
  border-radius: var(--bulma-radius-rounded);
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 0.5rem;
  align-items: stretch;
  background: var(--bulma-white-ter);

  &.has-skeleton {
    &:after {
      width: 100%;
    }
  }

  &.is-ltr {
    justify-content: flex-start;
  }

  &.is-rtl {
    justify-content: flex-end;
  }
}

.multi-progress-bar {
  display: flex;
  gap: var(--spacing-xxs);

  .progress-bar {
    flex: 1;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}