.stack {
  display: flex;

  &.minWNone {
    min-width: 0;
  }

  &.is-fullwidth {
    width: 100%;
    flex: 1;
  }

  &.is-fullheight {
    height: 100%;
  }

  &.is-wrap {
    flex-wrap: wrap;
  }

  &.direction-row {
    flex-direction: row;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-row-reverse {
    flex-direction: row-reverse;
  }

  &.direction-column-reverse {
    flex-direction: column-reverse;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-evenly {
    justify-content: space-evenly;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-baseline {
    align-items: baseline;
  }
}