@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

.rdrMonth {
  padding: 0;
  width: 100%;
}

.rdrMonthAndYearWrapper {
  height: 3.5rem;
  padding-top: var(--spacing-xs);
}

.rdrDayToday .rdrDayNumber span:after {
  background: var(--bulma-text);
}

.rdrCalendarWrapper {
  max-width: 25rem;
}