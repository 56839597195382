.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  margin: calc(-1 * var(--spacing-md));
  padding: var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.container {
  position: relative;
}