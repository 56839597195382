@use "src/scss/base";

.faq-item {
  border-radius: base.$radius;
  background: base.$white;
  border: 1px solid base.$border;

  &.is-open &__header {
    background: base.$white-ter;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: base.$radius;
    transition: background-color base.$speed base.$easing;

    &:hover {
      background: base.$white-bis;
    }

    &:active {
      background: base.$white-ter;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
  }

  &__content {
    transition: all base.$speed base.$easing;
    padding: 0 var(--spacing-md);
    max-height: 0;
    overflow: hidden;
  }

  &.is-open &__content {
    max-height: 350px;
    padding: var(--spacing-sm) var(--spacing-md);
  }
}