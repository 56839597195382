@use "src/scss/base";
$rt-mobile: 'only screen and (max-width : 1px)';
@import "~react-toastify/scss/main";

.Toastify {
  --toastify-text-color-light: var(--bulma-text);
  --toastify-color-light: white;
  --toastify-color-dark: base.$dark;
  --toastify-color-info: base.$info;
  --toastify-color-success: base.$success;
  --toastify-color-warning: base.$warning;
  --toastify-color-error: base.$danger;
  --toastify-color-progress-light: var(--bulma-success);

  color: inherit;

  .delete {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
  }

  &__toast {
    padding: var(--spacing-md);
  }

  &__toast-body {
    padding: 0;
    align-items: flex-start;
  }

  &__toast-icon {
    margin-right: var(--spacing-xs);
  }
}