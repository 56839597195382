.icon-progress-bar {
  &__header {
    &.has-children {
      cursor: pointer;
    }
  }

  &__content {
    transition: all var(--bulma-speed) var(--bulma-easing);
    padding-top: 0;
    margin-top: 0;
    border-top: 1px solid transparent;
    padding-left: 4.5rem;
    padding-right: 3rem;
    max-height: 0;
    overflow: hidden;

    &.is-active {
      max-height: 200px;
      border-top: 1px solid var(--bulma-border);
      padding-top: var(--spacing-md);
      margin-top: var(--spacing-md);
    }
  }
}