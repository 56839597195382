@use "./base" as *;
@use "bulma/sass/utilities/css-variables" as cv;
@use "bulma/sass/themes/light";
@use "bulma/sass/themes/dark";
@use "bulma/sass/themes/setup";
@use "~bulma/sass/utilities/mixins" as mx;
@use "~bulma/sass/base" with (
  $body-size: 14px,
);

.is-clipped {
  overflow: hidden;
}

:root {
  --spacing-none: 0px;
  --spacing-xxs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.25rem;
  --spacing-xl: 1.5rem;
  --spacing-xxl: 3rem;

  --hover-background: var(--bulma-white-bis);
  --active-background: var(--bulma-white-ter);

  // Original
  --app-background: #FFFDF2;
}

html {
  min-width: 320px;

  @include mx.mobile {
    font-size: 12px;
  }

  @include mx.until(360) {
    font-size: 10px;
  }

  @include mx.touch {
    background: var(--app-background) !important;
  }
}

:root {
  @include light.light-theme;
  @include setup.setup-theme;
}

.screenshot-wrapper {
  .print-hidden {
    display: none;
  }
}

.cky-btn-revisit-wrapper {
  background-color: #bdbdbd !important; /* neutral-400 equivalent */
}

.cky-btn-revisit-wrapper.dark {
  background-color: #2d2d2d !important; /* neutral-800 equivalent */
}

.cky-btn-revisit-wrapper::before {
  background-color: #bdbdbd !important;
}

.cky-btn-revisit-wrapper.dark::before {
  background-color: #2d2d2d !important;
}

.cky-btn-revisit-wrapper::after {
  border-right-color: #bdbdbd !important;
}

.cky-btn-revisit-wrapper.dark::after {
  border-right-color: #2d2d2d !important;
}

.cky-modal,
.cky-preference-center {
  background-color: white !important;
}

.cky-modal.dark,
.cky-preference-center.dark {
  background-color: #2d2d2d !important;
}

.cky-preference-content-wrapper,
.cky-dma-content-wrapper,
.cky-accordion-header-des,
.cky-accordion-btn,
.cky-preference-title {
  color: #1a1a1a !important; /* neutral-900 equivalent */
}

.dark .cky-preference-content-wrapper,
.dark .cky-dma-content-wrapper,
.dark .cky-accordion-header-des,
.dark .cky-accordion-btn,
.dark .cky-preference-title {
  color: #b3b3b3 !important; /* neutral-300 equivalent */
}

.cky-cookie-des-table.dark {
  background-color: #4d4d4d !important; /* neutral-700 equivalent */
}

.cky-btn {
  border-radius: 4px !important; /* rounded-md equivalent */
}

.cky-btn.cky-btn-accept {
  font-weight: 600 !important; /* font-semibold equivalent */
  color: white !important;
}

.dark .cky-btn.cky-btn-accept {
  color: #2d2d2d !important; /* neutral-800 equivalent */
}

.cky-btn.cky-btn-preferences,
.cky-btn.cky-btn-customize,
.cky-btn.cky-btn-reject {
  border-color: #4d4d4d !important; /* neutral-700 equivalent */
  color: #4d4d4d !important;
}

.dark .cky-btn.cky-btn-preferences,
.dark .cky-btn.cky-btn-customize,
.dark .cky-btn.cky-btn-reject {
  color: #b3b3b3 !important; /* neutral-300 equivalent */
  border-color: #b3b3b3 !important;
}

.cky-footer-shadow {
  background: linear-gradient(to bottom, transparent, white) !important;
}

.dark .cky-footer-shadow {
  background: linear-gradient(to bottom, transparent, #1a1a1a) !important; /* neutral-900 equivalent */
}

.cky-consent-bar {
  background-color: white !important;
  border-color: #e0e0e0 !important; /* neutral-200 equivalent */
}

.dark .cky-consent-bar {
  background-color: #1a1a1a !important; /* neutral-900 equivalent */
  border-color: #2d2d2d !important; /* neutral-800 equivalent */
  box-shadow: none !important;
}

.cky-title,
.cky-notice-des {
  color: #1a1a1a !important;
}

.dark .cky-title,
.dark .cky-notice-des {
  color: #b3b3b3 !important; /* neutral-300 equivalent */
}

[data-cky-tag="detail-powered-by"].dark {
  background-color: #b3b3b3 !important; /* neutral-300 equivalent */
}

a.cky-banner-element {
  padding: 8px 30px;
  background-color: #F8F9FA;
  color: #858A8F;
  border: 1px solid #DEE2E6;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
}

.cky-btn-revisit-wrapper {
  @include mx.touch {
    display: none !important;
  }
}


@import "bulma/sass/base/skeleton";
@import "bulma/sass/helpers/visibility";
@import "bulma/sass/helpers/other";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
