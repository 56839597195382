@use "bulma/sass/components/modal";

.drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 40;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  transition: all var(--bulma-speed) var(--bulma-easing);

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  &-card {
    background: var(--bulma-body-background-color);
    position: relative;
    z-index: 1;
    width: 40rem;
    margin: var(--spacing-md);
    border-radius: var(--bulma-radius-large);
    display: flex;
    flex-direction: column;
    transition: all var(--bulma-speed) var(--bulma-easing);
    transform: translateX(100%);
    opacity: 0;
    max-width: calc(100% - calc(var(--spacing-md) * 2));
  }

  &.is-active &-card {
    opacity: 1;
    transform: translateX(0);
  }

  &-background {
    cursor: pointer;
  }

  &-card-body {
    padding: var(--spacing-xl);
    flex: 1;
    max-height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 3px;
      z-index: 1;
      padding-bottom: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--bulma-border);
      border-radius: var(--bulma-radius);
    }
  }

  &-card-head {
    padding: var(--spacing-xl);
    border-bottom: 1px solid var(--bulma-border);
  }

  &-background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), 0.66);
    backdrop-filter: blur(3px);
    z-index: 0;
  }
}