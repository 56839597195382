@use "src/scss/base" as base;

.dashboard-columns {
  @include base.tablet {
    --bulma-column-gap: 1.5rem;
  }

  @include base.mobile {
    flex-wrap: nowrap;
    max-width: 100vw;
    --bulma-column-gap: 0rem;

    > .column:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
}