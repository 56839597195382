@use "src/scss/base" as base;

.sidebar {
  box-shadow: base.$box-shadow;
  background: var(--bulma-body-background-color);
  padding: var(--spacing-lg);
  border-radius: var(--bulma-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  position: sticky;
  top: 0;

  &__header {
    padding-bottom: var(--spacing-md) !important;
    border-bottom: 1px solid var(--bulma-border);
  }

  &__footer {
    border-top: 1px solid var(--bulma-border);
    padding-top: var(--spacing-md);
  }
}