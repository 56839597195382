.radio {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
  position: relative;

  .icon {
    color: transparent;
    width: 0.94rem;
    height: 0.94rem;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: var(--bulma-radius);
  }

  .radio-toggle {
    width: 1.5rem;
    flex: 0 0 1.5rem;
    height: 1.5rem;
    border-radius: var(--bulma-radius-rounded);
    border: 1px solid var(--bulma-grey-light);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color, var(--bulma-speed), var(--bulma-easing);
  }

  input:checked + .radio-toggle {
    .icon {
      color: var(--bulma-body);
    }
  }

  &.is-primary input:checked + .radio-toggle {
    .icon {
      color: var(--bulma-primary);
    }
  }

  &.is-success input:checked + .radio-toggle {
    .icon {
      color: var(--bulma-success);
    }
  }

  &.is-info input:checked + .radio-toggle {
    .icon {
      color: var(--bulma-info);
    }
  }

  &.is-warning input:checked + .radio-toggle {
    .icon {
      color: var(--bulma-warning);
    }
  }

  &.is-danger input:checked + .radio-toggle {
    .icon {
      color: var(--bulma-danger);
    }
  }

  &:hover {
    .radio-toggle {
      border-color: var(--bulma-grey);
      color: var(--bulma-grey);
    }
  }

  &:active,
  input:checked + {
    .radio-toggle {
      border-color: var(--bulma-body);
    }
  }
}