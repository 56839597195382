.subscription-box {
  height: 100%;

  .box__body {
    height: 100%;
  }

  &__inner {
    height: 100%;
    justify-content: space-between;
  }
}

.subscription-feature-item {
  display: flex;
  align-items: flex-start !important;
  flex-wrap: nowrap;
  gap: var(--spacing-xs);
}