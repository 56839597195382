@use "bulma/sass/components/modal";

.modal {
  display: flex !important;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--bulma-speed) var(--bulma-easing);

  &.is-active {
    pointer-events: all;
    opacity: 1;
  }

  &-background {
    background: hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), 0.66) !important;
    backdrop-filter: blur(3px);
  }

  &.is-large &-card {
    width: 52.5rem;
  }

  &-card {
    border-radius: var(--bulma-radius-large);
    transform: translateY(50%);
    transition: all var(--bulma-speed) var(--bulma-easing);
    max-width: calc(100% - calc(var(--spacing-md) * 2));
    max-height: calc(100dvh - calc(var(--spacing-md) * 2)) !important;

    &-head {
      padding: 1.5rem !important;
      box-shadow: none !important;
      border-bottom: 1px solid var(--bulma-border);
      background-color: var(--bulma-body-background-color) !important;
    }

    &-foot {
      padding: 1.5rem !important;
      box-shadow: none !important;
      border-top: 1px solid var(--bulma-border);
      background-color: var(--bulma-body-background-color) !important;
    }

    &-body {
      padding: 1.5rem !important;
    }
  }

  &.is-active &-card {
    transform: translateY(0);
  }
}