@use "src/scss/base";


.PhoneInputCountry {
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  .PhoneInputCountrySelectArrow {
    display: none;
  }
}

.first-name-column {
  @include base.mobile {
    padding-bottom: 0;
  }
}

.first-time-sign-in-page {
  .login-logo {
    margin-top: -70px;
  }
}