@use "src/scss/base" as base;
@use "bulma/sass/components/menu" with (
  $menu-list-line-height: 1.5
);

.menu-list {

  @include base.mobile {
    border-bottom: 1px solid var(--bulma-border);
    padding-bottom: var(--spacing-xl);
  }

  li {
    &:not(:last-child) {
      margin-bottom: var(--spacing-xs);
    }
  }

  a {
    &.is-active {
      background: var(--bulma-text-light);
      color: var(--bulma-text-light-invert);
    }
  }
}