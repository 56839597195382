@use "src/scss/base" as base;

.roi-chart {
  min-height: 160px;
  width: 100%;
}


.mobile-tip {
  &__header {
    border-radius: base.$radius;
    background: base.$white-ter;
    padding: var(--spacing-xs) var(--spacing-md);
  }
}