@use "src/scss/base" as base;

.static-page-wrapper {
  min-height: 100dvh;
  background: var(--app-background);
}

.static-page {
  background: var(--bulma-body-background-color);
  padding: var(--spacing-xxl) 0;

  img {
    border-radius: var(--bulma-radius);
    box-shadow: base.$box-shadow;
  }
}